<template>
    <div class="wrap">
        <my-header></my-header>
        <div class="section">
            <div class="banner">
                <transition name="slide-fade">
                    <div v-if="show">
                        <p>{{ $t('Public.NEVService.banner.title') }}</p>
                        <div class="banner-txt">{{ $t('Public.NEVService.banner.text') }}</div>
                    </div>
                </transition>
            </div>
            <div class="content">
                <div class="service">
                    <p class="detail_title">{{ $t('Public.NEVService.extended.title') }}</p>
                    <div class="service_info">
                        <div class="service_text">
                            <p v-if="$i18n.locale == 'CN'">新能源系列服务，<br />为新能源车主提供全面的延保服务和全方位的出行保障</p>
                            <p v-else>Innovation Warranty for New Energy Vehicles</p>
                            <div>
                                <div class="active">{{ $t('Public.NEVService.extended.service.battery1') }}</div>
                                {{ $t('Public.NEVService.extended.service.battery2') }}<br />
                                <div class="active">{{ $t('Public.NEVService.extended.service.warranty1') }}</div>
                                {{ $t('Public.NEVService.extended.service.warranty2') }}
                                <!-- <template v-if="$i18n.locale!='EN'"> -->
                                <div class="active">{{ $t('Public.NEVService.extended.service.warranty3') }}</div>
                                {{ $t('Public.NEVService.extended.service.warranty4') }}
                                <!-- </template> -->
                            </div>
                        </div>
                        <div class="service_img">
                            <img src="../../public/images/service/nev/service_icon.png" />
                        </div>
                    </div>
                </div>
                <div class="solution">
                    <p class="detail_title">{{ $t('Public.NEVService.solutions.title') }}</p>
                    <div class="solution_text">{{ $t('Public.NEVService.solutions.intro') }}</div>
                    <ul>
                        <li v-for="(item, index) in $t('Public.NEVService.solutions.list')" :key="index">
                            <div><img :src="require('../../public/images/service/nev/solution_icon' + (index + 1) + '.png')" /></div>
                            <p>{{ item.title }}</p>
                            <p>{{ item.title2 }}</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <my-footer></my-footer>
    </div>
</template>

<script>
// @ is an alias to /src
import MyHeader from '@/components/MyHeader.vue'
import MyFooter from '@/components/MyFooter.vue'

export default {
    name: 'NEVService',
    components: {
        MyHeader,
        MyFooter
    },
    data() {
        return {
            show: false
        }
    },
    methods: {},
    mounted() {
        this.show = true
    }
}
</script>
<style scoped>
.service {
    padding: 1.1rem 3.6rem 1rem;
}
.service_info {
    margin-top: 0.7rem;
    display: flex;
    font-size: 0.2rem;
    font-weight: 400;
    color: #333333;
    line-height: 2;
}
.service_text p {
    font-weight: 600;
    margin-bottom: 0.26rem;
    /* margin-top:0.2rem; */
    line-height: 1.82;
}
.service_text .active {
    display: flex;
    align-items: center;
}
.service_text .active::before {
    display: block;
    content: '';
    width: 0.08rem;
    height: 0.08rem;
    background: #05a3e4;
    margin-right: 0.1rem;
    border-radius: 50%;
}
.service_text {
    width: 6.5rem;
    margin-right: 0.1rem;
}
.service_img {
    width: 5.2rem;
    height: 3.8rem;
    overflow: hidden;
}
.service_img img {
    cursor: pointer;
    transition: all 0.8s;
}
.service_img img:hover {
    transform: scale(1.3);
}
.banner {
    width: 100%;
    height: 5.76rem;
    padding: 1.8rem 4.3rem 0 3.6rem;
    font-size: 0.22rem;
    font-weight: 300;
    background: url(../../public/images/service/nev/banner.jpg) no-repeat;
    background-size: 100% 100%;
    background-color: #31575c;
    line-height: 2;
}
.banner p {
    font-size: 0.54rem;
    line-height: 1.39;
    margin-bottom: 0.28rem;
}
.banner-txt {
    text-align: justify;
}
.content {
    color: #333;
}
.solution {
    padding: 1rem 3.6rem 1.3rem;
    background: #f4f3f3;
}
.solution li:hover {
    transform: translateY(-0.2rem);
    cursor: pointer;
    box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.15);
}

.solution_text {
    margin: 1rem 0 0.64rem;
    font-size: 0.2rem;
    line-height: 2;
    text-align: justify;
}
.solution ul {
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.solution li {
    width: 2.86rem;
    min-height: 1.6rem;
    background: #ffffff;
    padding: 0.24rem 0.24rem 0.16rem;
    font-size: 0.2rem;
    font-weight: 500;
    transition: all 0.6s;
    /* text-align:justify; */
}
.solution li p {
    line-height: 1.4;
}
.solution li div {
    width: 0.54rem;
    margin-bottom: 0.2rem;
}
.detail_title {
    font-size: 0.32rem;
    font-weight: 600;
    text-align: center;
}
</style>
